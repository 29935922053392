<template>
  <nav class="sidebar" v-if="slateAuth.token">
    <article class="mainbar">
      <header>
        <div class="nav-icon" @click="toggleSubBar('menu');">
          <i class="fas fa-bars" />
        </div>
      </header>

      <section class="favorites" @click="hideSubBar();">
        <router-link class="nav-icon" :to="{name: 'home'}">
          <icon icon="home" />
        </router-link>

        <!-- <router-link class="nav-icon" :to="{name: 'rfqResponseList'}">
          <icon icon="comments-alt-dollar" />
        </router-link> -->

        <router-link class="nav-icon" :to="{name: 'vendorEstimateResponseList'}">
          <icon icon="file-invoice" />
        </router-link>
      </section>

      <section class="utilities">
        <div class="nav-icon has-text-danger" v-if="!online">
          <icon icon="exclamation-triangle" />
        </div>

        <div class="nav-icon" @click="toggleSubBar('options');">
          <icon icon="cogs" />
        </div>
      </section>
    </article>

    <article class="subbar" :class="{visible: showSubBar['menu']}" @click="hideSubBar();">
      <b-menu>
        <b-menu-list label="Slate">
          <li>
            <router-link :to="{name: 'home'}">Home</router-link>
          </li>
        </b-menu-list>
        <!-- <b-menu-list label="Request for Quote">
          <li>
            <router-link :to="{name: 'rfqResponseList'}">RFQ Responses</router-link>
          </li>
        </b-menu-list> -->
        <b-menu-list label="Vendor Estimate Responses">
          <li>
            <router-link :to="{name: 'vendorEstimateResponseList'}">Vendor Estimate Responses</router-link>
          </li>
        </b-menu-list>
      </b-menu>
    </article>

    <article class="subbar" :class="{visible: showSubBar['options']}">
      <div class="options">
        <section class="settings">
          <b-field :label="$t('APP.LANGUAGE')">
            <b-select v-model="$i18n.locale" expanded>
              <option
                v-for="(lang) in langs"
                :key="lang"
                :value="lang"
              >{{ $t(`APP.LOCALES.${lang}`) }}</option>
            </b-select>
          </b-field>
        </section>
        <section class="actions">
          <b-menu>
            <b-menu-list>
              <b-menu-item
                class="menu-link"
                icon="sign-out"
                :label="$t('APP.LOGOUT')"
                @click="slateAuth.logout()"
              ></b-menu-item>
            </b-menu-list>
          </b-menu>
        </section>
      </div>
    </article>
  </nav>
</template>

<script>
export default {
  data: () => ({
    showSubBar: {},
    langs: ['en', 'zh', 'es'],
    online: navigator.onLine,

    removeRouteGuardHandler() {},
  }),
  methods: {
    toggleSubBar(subBar) {
      Object.keys(this.showSubBar).forEach((item) => {
        if (item !== subBar) {
          this.$set(this.showSubBar, item, false);
        }
      });
      this.$set(this.showSubBar, subBar, !this.showSubBar[subBar]);
    },
    hideSubBar() {
      Object.keys(this.showSubBar).forEach((item) => {
        this.$set(this.showSubBar, item, false);
      });
    },
    setLang(lang) {
      this.$i18n.locale = lang;
    },
    globalEscHandler(e) {
      if (e.key === 'Escape') {
        this.hideSubBar();
      }
    },
    onlineStatusChange() {
      this.online = navigator.onLine;
      if (!this.online) {
        this.$buefy.snackbar.open({
          message: this.$t('APP.OFFLINE_SNACKBAR'),
          type: 'is-danger',
          queue: false,
        });
      }
    },
  },
  mounted() {
    this.removeRouteGuardHandler = this.$router.beforeEach((to, from, next) => {
      this.hideSubBar();
      return next();
    });
    document.addEventListener('keyup', this.globalEscHandler, true);

    window.addEventListener('online', this.onlineStatusChange);
    window.addEventListener('offline', this.onlineStatusChange);
    this.onlineStatusChange();
  },
  beforeDestroy() {
    this.removeRouteGuardHandler();
    window.removeEventListener('keyup', this.globalEscHandler);

    window.removeEventListener('online', this.onlineStatusChange);
    window.removeEventListener('offline', this.onlineStatusChange);
  },
};
</script>


<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.sidebar {
  @media print {
    display: none !important;
  }
  z-index: 4000;
  display: inline-block;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100vh;

  .mainbar {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 5000;
    height: 100vh;

    background-color: #26363b;
    color: #eee;
    box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.75);

    header {
      background-color: $primary;
      color: #eee;

      border-bottom: 1px solid #eee;
      .nav-icon {
        margin: 0 auto;
      }
      margin-bottom: 1.2rem;
    }

    .utilities {
      border-top: 1px solid #eee;
      .nav-icon {
        margin: 0 auto;
      }
      margin-top: 1.2rem;
    }

    .favorites {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .nav-icon {
      flex: 0 0 auto;
      font-size: 2.4rem;
      line-height: 3.6rem;
      text-align: center;
      display: block;
      width: 6.4rem;
      height: 6.4rem;
      padding: 1.2rem;
      margin: 0.6rem auto;
      overflow: hidden;
      &.router-link-active {
        padding-left: 1rem;
        border-left: 0.2rem solid #eee;
      }
    }

    a,
    a:visited,
    a:hover {
      color: #eee;
      &.router-link-exact-active {
        color: #fff;
        text-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.75);
      }
    }

    a:hover {
      text-shadow: 0px 0px 1.2rem rgba(0, 0, 0, 0.5);
    }
  }

  .subbar {
    display: inline-block;
    position: absolute;
    top: 0;
    width: 24rem;
    left: calc(-24rem - 100%);
    z-index: 4000;
    height: 100vh;
    transition: left 0.4s;

    background: #eee;

    padding: 1.2rem;
    font-size: 1.6rem;

    &.visible {
      left: 100%;
      box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.75);
    }

    .options {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .settings {
        flex: 1 1 auto;
      }
    }

    .menu {
      font-size: 1.6rem;
      ::v-deep a {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .icon {
          margin-right: 0.6rem;
        }
      }
    }
  }
}
</style>
