<template>
  <main>
    <sidebar id="main-sidebar" />
    <section id="main-content">
      <router-view>
        <div class="columns is-mobile is-centered">
        <div class="column is-half">
          <br><br><br>
          <div class="box">
            <h2>WELCOME TO SLATE!</h2>
            <br>
            <p>Click <icon icon="comments-alt-dollar" /> icon to view your requests for quote. Thank you for being a key jansy vendor!</p>
            <br>
            <p>Please contact <a href="mailto:jyu@jansypkg-cn.com">jyu@jansypkg-cn.com</a> and <a href="mailto:helpdesk@jansypkg.com">helpdesk@jansypkg.com</a> for any technical issues.</p>
          </div>
        </div>
      </div>
      </router-view>
    </section>
  </main>
</template>

<script>
import sidebar from './sidebar.vue';

export default {
  components: {
    sidebar,
  },
};
</script>

<style lang="scss">
main {
  display: flex;
  #main-sidebar {
    flex: 0 0 auto;
  }
  #main-content {
    flex: 1 1 auto;
    min-height: 100vh;
  }
}
</style>
